import ApiService from "@/core/services/api.service";

export const loadMaster = (type = "dropdown", table, params = "") => {
  return new Promise((resolve, reject) => {
    ApiService.get(type, table+params, 1).then((data) => {
      resolve(data)
    }).catch((response) => {
      reject(response);
    })
  })
}

export const loadStatus = () => {
  return new Promise((resolve) => {
    let data = [
      {text: "Active", value: 1},
      {text: "In-active", value: 2}
    ]

    resolve(data);
  });
}

export const loadTipeSupplier = () => {
  return new Promise((resolve) => {
    let data = [
      {text: "Supplier", value: 1},
      {text: "Mitra/Donatur", value: 2}
    ]

    resolve(data);
  });
}

export const loadSkalaUsaha = () => {
  return new Promise((resolve) => {
    let data = [
      {text: "Lokal", value: 1},
      {text: "Nasional", value: 2}
    ]

    resolve(data);
  });
}

export const loadJenisUsaha = () => {
  return new Promise((resolve) => {
    let data = [
      {text: "Distributor", value: 1},
      {text: "Non-distributor", value: 2}
    ]

    resolve(data);
  });
}

export const loadBadanUsaha = () => {
  return new Promise((resolve) => {
    let data = [
      {text: "Individu", value: 1},
      {text: "UD", value: 2},
      {text: "CV", value: 3},
      {text: "PT", value: 4},
      {text: "Koperasi", value: 5},
    ]

    resolve(data);
  });
}

export const loadStatusRequest = () => {
  return new Promise((resolve) => {
    let data = [
      {text: "Pending", value: 1, color: "warning"},
      {text: "Process", value: 2, color: "primary"},
      {text: "Success", value: 3, color: "success"},
      {text: "Retur", value: 4, color: "danger"}
    ]

    resolve(data);
  });
}

export default {
  loadMaster,
  loadStatus,
  loadTipeSupplier,
  loadSkalaUsaha,
  loadJenisUsaha,
  loadBadanUsaha,
  loadStatusRequest
};
